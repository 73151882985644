import React from 'react';

export interface HelpSvgProps {}

const HelpSvg: React.FunctionComponent<HelpSvgProps> = () => (
  <svg width='16' height='21' viewBox='0 0 16 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.738 4.2H11.2354C11.666 4.2 11.9937 3.86217 11.9937 3.46044V2.28261C11.9937 1.86261 11.6473 1.54304 11.2354 1.54304H10.0183C9.78423 0.657391 8.96971 0 7.97732 0C6.98493 0 6.17042 0.657391 5.927 1.55217H4.71928C4.28861 1.55217 3.96094 1.89 3.96094 2.29174V3.46957C3.96094 3.86217 4.30734 4.2 4.738 4.2ZM7.97732 1.15957C8.46416 1.15957 8.83864 1.52478 8.83864 1.99957C8.83864 2.47435 8.46416 2.83957 7.97732 2.83957C7.49049 2.83957 7.116 2.47435 7.116 1.99957C7.116 1.55217 7.49049 1.15957 7.97732 1.15957Z'
      fill='white'
    />
    <path
      d='M15.9719 4.43744C15.9719 3.41483 15.1106 2.57483 14.062 2.57483H13.1164V3.41483C13.1164 4.43744 12.2832 5.25005 11.2346 5.25005H4.73727C3.68871 5.25005 2.85547 4.43744 2.85547 3.41483V2.57483H1.90989C0.861322 2.57483 0 3.41483 0 4.43744V19.1283C0 20.1509 0.861322 20.9909 1.90989 20.9909H4.44705C4.50322 21 4.6062 21 4.76536 20.9909H11.7402C11.8619 20.9909 11.9743 20.9909 12.0866 20.9909H14.0901C15.1387 20.9909 16 20.1509 16 19.1283L15.9719 4.43744ZM3.1483 8.67396H12.742C13.0696 8.67396 13.3037 8.91135 13.3037 9.22179C13.3037 9.54135 13.0603 9.76961 12.742 9.76961H3.1483C2.82062 9.76961 2.58657 9.53222 2.58657 9.22179C2.58657 8.91135 2.85807 8.67396 3.1483 8.67396ZM3.1483 12.7187H12.742C13.0696 12.7187 13.3037 12.9561 13.3037 13.2666C13.3037 13.5861 13.0603 13.8144 12.742 13.8144H3.1483C2.82062 13.8144 2.58657 13.577 2.58657 13.2666C2.58657 12.9561 2.85807 12.7187 3.1483 12.7187ZM3.1483 16.7635H9.53072C9.8584 16.7635 10.0925 17.0009 10.0925 17.3114C10.0925 17.6309 9.84904 17.8592 9.53072 17.8592H3.1483C2.82062 17.8592 2.58657 17.6218 2.58657 17.3114C2.58657 17.0009 2.82999 16.7635 3.1483 16.7635Z'
      fill='white'
    />
  </svg>
);

export default HelpSvg;
